<template>

    <component :is="tag? tag : 'div'" style="position:relative;">

       <slot :item="item" :ind="ind" :deleteItem="deleteItem" name="form">
           <input type="text" class="form-control"  placeholder="your items form" id="">
       </slot>

    </component>


</template>

<script>

    export default {
        components: {},
        props: ['item', 'ind', 'tag'],
        methods:{
            deleteItem(){
                this.$emit('delete-item', (this.item.id ? this.item : this.ind));
            }
        }

    }
</script>


